<template>
  <div class='px-2 lg:px-0 pt-4 lg:pt-12'>
    <div class='flex flex-wrap lg:flex-row mb-8 gap-x-2 gap-y-1'>
      <div v-if='showSessionDatesFilter'
        class='flex flex-row justify-start items-center w-full'>
        <label v-for='sessionDate in selectableDates'
          :key='`session-date-radio-${sessionDate}`'
          class='date-option flex-grow lg:flex-1 flex flex-row justify-center items-center py-2 lg:py-4 px-1 lg:px-6 gap-x-1 border text-xs lg:text-xl uppercase font-medium cursor-pointer'
          :class='selectedDateStyleLabel(sessionDate)'>
          <input type='radio'
            class='hidden'
            v-model='sessionDetailsListSelectedDate'
            :value='sessionDate'/>
            {{ sessionDateString(sessionDate) }}
        </label>
      </div>
      <div class='flex flex-col lg:flex-row justify-between items-start lg:items-center w-full mt-4 gap-y-2 lg:gap-x-4'>
        <div v-if='showSessionRoomsFilter'
          class='flex flex-row justify-start items-center w-full'>
          <label v-for="sessionRoom in selectableRooms"
            :key='`session-room-radio-${sessionRoom}`'
            class='room-option flex-1 flex flex-row justify-center items-center py-2 px-1 lg:px-2 gap-x-1 border text-xs lg:text-sm uppercase font-medium cursor-pointer'
            :class='selectedRoomStyleLabel(sessionRoom)'>
            <input type='radio'
              class='hidden'
              v-model='sessionDetailsListSelectedRoom'
              :value='sessionRoom'/>
              {{ sessionRoomString(sessionRoom) }}
          </label>
        </div>
        <button
          @click='openFilters'
          class='uppercase py-2 px-2 rounded border border-gray-200 hover:border-gray-300 hover:bg-gray-100 hover:shadow flex flex-row justify-start items-center gap-x-2'>
          <filter-icon class='h-5' />
          <span class='text-sm font-semibold'>filters: </span>
          <span class='text-xs text-gray-600 leading-relaxed whitespace-nowrap'>{{currentFiltersString}}</span>
        </button>
      </div>
    </div>
    <session-details-full
      v-for='(sessionDetail, index) in sessionDetailsListAllCategoriesWithExceptions'
      :key='`sessionDetail-${sessionDetail.id}`'
      :session='sessionDetail'
      :repeatedComponent='true'
      :session-index='index'
      class='mb-12 lg:mb-16 pb-4 p-0 lg:p-6 border-b lg:border border-gray-100 lg:rounded-md lg:shadow-md' />
    <div v-if='emptySessionDetailsList'
      class='px-16 py-24 text-center bg-gray-100 rounded-md max-w-4xl'>
      No sessions found. Please update your filter settings.
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { FilterIcon } from '@vue-hero-icons/outline'

import SessionDetailsFull from '@/components/icola2023/SessionDetailsFull.vue'
import cloneDeep          from 'lodash/cloneDeep'
import dayjs from 'dayjs'

export default {
  name: 'ProgramDetails',
  components: {
    SessionDetailsFull,
    FilterIcon,
  },
  props: [
    'needToBeLoggedIn',
  ],
  data () {
    return {
      filterList: '',
      sessionDetailsListSelectedCatogory: '',
      sessionDetailsListSelectedDate: '',
      sessionDetailsListSelectedRoom: '',
    }
  },
  watch: {
    'queryParams': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let newQueryParams = cloneDeep(newVal)
          Object.keys(this.$route.query).forEach(key => {
            if (!newQueryParams[key]) {
              newQueryParams[key] = this.$route.query[key]
            }
          })
          this.$router.push({query: newQueryParams}).catch(() => {})
          this.getSessionDetailsList(newVal)
        }
      },
      deep: true,
    }
  },
  computed: {
    ...mapState('sessions', [
      'sessionDetailsList',
      'sessionCategoryFilters',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('filters', [
      'showingContentsFilterListByFilterName',
    ]),
    ...mapGetters('events', [
      'eventSecondaryThemeColor',
    ]),
    ...mapGetters('sessions', [
      'hasSessionCategoryFilters',
    ]),
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    queryParams () {
      let categoryParam = (this.sessionDetailsListSelectedCatogory === 'All Categories') ? '' : this.sessionDetailsListSelectedCatogory
      let dateParam     = this.sessionDetailsListSelectedDate
      let roomParam     = this.sessionDetailsListSelectedRoom
      return {
        categories: categoryParam,
        sessionDate: dateParam,
        sessionRoom: roomParam
      }
    },
    sessionDetailsListAllCategoriesWithExceptions () {
      return this.sessionDetailsList.filter(session => {
        return session.category !== 'break' && 
               session.sessionType !== 'break' &&
               !session.configurations.hideSessionInProgramDetails && 
               (!this.hasSessionCategoryFilters || this.sessionCategoryFilters.includes(session.category))

      })
    },
    emptySessionDetailsList () {
      return this.sessionDetailsListAllCategoriesWithExceptions.length === 0
    },
    selectableCategories () {
      let list = this.showingContentsFilterListByFilterName('session_detail', 'category')
      return [...list, 'All Categories']
    },
    selectableDates () {
      let list =  this.showingContentsFilterListByFilterName('session_detail', 'dates')
      return [ ...list]
    },
    selectableRooms () {
      let list =  this.showingContentsFilterListByFilterName('session_detail', 'rooms')
      return [ ...list]
    },
    showSessionDatesFilter () {
      return this.selectableDates.length > 1
    },
    showSessionRoomsFilter () {
      return this.selectableRooms.length > 1
    },
    currentFiltersString () {
      return (this.hasSessionCategoryFilters) ? this.sessionCategoryFilters.join(', ') : 'show all'
    },
  },
  methods: {
    ...mapActions([
      'sidepanelOpen',
    ]),
    ...mapActions('sessions', [
      'getSessionDetailsList',
    ]),
    ...mapActions('filters', [
      'getFilters',
    ]),
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
    openFilters () {
      this.sidepanelOpen({ componentName: 'session-filters' })
    },
    sessionDateString (dateString) {
      if (this.isMobile) {
        return dateString = dayjs(dateString).format('MMM DD (ddd)')
      } else {
        return dateString = dayjs(dateString).format('MMMM DD (ddd)')
      }
    },
    selectedDateStyleLabel (value) {
      return this.sessionDetailsListSelectedDate === value ? 'selected-date-label' : ''
    },
    sessionRoomString (roomString) {
      return roomString = roomString.split('||')[0]
    },
    selectedRoomStyleLabel (value) {
      return this.sessionDetailsListSelectedRoom === value ? 'selected-room-label' : ''
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken && this.needToBeLoggedIn) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'ProgramDetails'} })
      }
    })
  },
  mounted () {
    this.getFilters().then(() => {
      this.$nextTick(() => {
        if (!this.sessionDetailsListSelectedCatogory) {
          this.sessionDetailsListSelectedCatogory = this.showingContentsFilterListByFilterName('session_detail', 'category')[0]
          this.sessionDetailsListSelectedDate = this.selectableDates[0]
          this.sessionDetailsListSelectedRoom = this.selectableRooms[0]
        }
      })
    })
  }
}
</script>

<style lang='scss' scoped>
.selected-date-label {
  @apply text-white shadow-md;
  background-color: var(--eventMainColor);
  border-color: var(--eventMainColor);
}

.selected-room-label {
  @apply text-white shadow-md;
  background-color: var(--secondaryColor);
  border-color: var(--secondaryColor);
}

.date-option,
.room-option {
  @apply border-l-0;
}
.date-option:first-of-type,
.room-option:first-of-type {
  @apply rounded-l-md border-l;
}
.date-option:last-of-type,
.room-option:last-of-type {
  @apply rounded-r-md;
}

</style>

